import React from 'react';
import styled from '@emotion/styled';
import Button from '@leafygreen-ui/button';
import FormFooter, { FormFooterProps } from '@leafygreen-ui/form-footer';
import { palette } from '@leafygreen-ui/palette';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';

import { passThroughProps } from 'baas-ui/common/utils/util';

import { FormRow, FormRowInputGroup, SaveButton, SaveStatus } from '.';

import './form-row.less';

export enum TestSelector {
  FormRowContainer = 'form-row-container',
  FormFooterSaveButton = 'save-button-form-footer',
  FormFooterCancelButton = 'cancel-button-form-footer',
  FormFooterDiscardButton = 'discard-button-form-footer',
}

const baseClassName = 'form-row';
const formRowButtonFooterButtonClassName = `${baseClassName}-button-footer-actions-container-button`;

/* eslint-disable max-len */
const StyledFormFooter = styled(FormFooter as React.ComponentType<FormFooterProps>)`
  box-shadow: none;
  border-top: none;

  /* super fragile, would not recommend: styling copied from: https://github.com/mongodb/leafygreen-ui/blob/3364b542d6e25f8252c7bc00b3e7fcba80675508/packages/button/src/Button/Button.styles.ts#L126 */
  button[data-testid='lg-form_footer-back_button'] {
    background-color: transparent;
    border-color: ${palette.red.light1};
    color: ${palette.red.base};

    &:focus-visible,
    &[data-focus='true'] {
      color: ${palette.red.base};
    }

    &:hover,
    &[data-hover='true'],
    &:active,
    &[data-active='true'] {
      color: ${palette.red.dark2};
      background-color: ${transparentize(0.96, palette.red.base)};
      border-color: ${palette.red.base};
      box-shadow: 0px 0px 0px 3px ${palette.red.light3};
    }
  }
`;
/* eslint-enable max-len */

export interface Props {
  buttonNamePrefix?: string;
  className?: string;
  editing?: boolean;
  formIsDirty?: boolean;
  hasValidationError?: boolean;
  isDraft?: boolean;
  onCancel?(): any;
  onDiscard(): any;
  onSave(): any;
  saveButtonDisabled?: boolean;
  saveError?: string;
  saveErrorMessage?: string;
  saving?: boolean;
  useLeafyGreenFooter?: boolean;
  onDelete?(): void;
  'data-testid'?: string;
}

const FormRowButtonFooter = ({
  buttonNamePrefix,
  className,
  editing,
  formIsDirty,
  hasValidationError,
  isDraft,
  onCancel,
  onDelete,
  onDiscard,
  onSave,
  saveButtonDisabled,
  saveError,
  saveErrorMessage,
  saving,
  useLeafyGreenFooter,
  'data-testid': dataTestId,
  ...rest
}: Props) => {
  const saveButton = (
    <SaveButton
      className={formRowButtonFooterButtonClassName}
      namePrefix={buttonNamePrefix}
      onSave={onSave}
      isDirty={formIsDirty}
      saving={saving}
      editing={editing}
      disabled={saveButtonDisabled}
      isDraft={isDraft}
      data-testid={TestSelector.FormFooterSaveButton}
    />
  );

  const newButtons = (
    <>
      {!saving && (
        <Button
          name={`${buttonNamePrefix}Cancel`}
          className={formRowButtonFooterButtonClassName}
          onClick={onCancel}
          data-testid={TestSelector.FormFooterCancelButton}
        >
          Cancel
        </Button>
      )}
      {saveButton}
    </>
  );

  const saveButtonProps = {
    children: formIsDirty || !editing ? 'Save' : 'No Changes',
    onClick: onSave,
    disabled: saveButtonDisabled || !(formIsDirty || !editing) || saving,
  };

  const newCancelProps = !saving
    ? {
        children: 'Cancel',
        onClick: onCancel,
      }
    : undefined;

  const editingCancelProps =
    formIsDirty && !saving
      ? {
          children: 'Discard Changes',
          onClick: onDiscard,
        }
      : undefined;

  const editButtons = (
    <>
      {formIsDirty && !saving && (
        <Button
          name={`${buttonNamePrefix}Discard`}
          className={formRowButtonFooterButtonClassName}
          onClick={onDiscard}
          data-testid={TestSelector.FormFooterDiscardButton}
        >
          Discard Changes
        </Button>
      )}
      {saveButton}
    </>
  );

  return useLeafyGreenFooter ? (
    <StyledFormFooter
      data-testid={dataTestId}
      primaryButtonProps={saveButtonProps}
      cancelButtonProps={editing ? editingCancelProps : newCancelProps}
      backButtonProps={
        editing
          ? {
              leftGlyph: undefined,
              children: 'Delete Trigger',
              onClick: () => {
                if (onDelete) {
                  onDelete();
                }
              },
            }
          : undefined
      }
      errorMessage={saveErrorMessage}
    />
  ) : (
    <FormRow
      last
      className={className}
      data-testid={dataTestId || TestSelector.FormRowContainer}
      {...passThroughProps(rest)}
    >
      <FormRowInputGroup className="form-row-button-footer-actions-container" right>
        <SaveStatus
          saving={saving}
          saveError={saveError}
          saveErrorMessage={saveErrorMessage}
          hasValidationError={hasValidationError}
          className="form-footer-save-status"
          isDraft={isDraft}
        />
        {editing ? editButtons : newButtons}
      </FormRowInputGroup>
    </FormRow>
  );
};

FormRowButtonFooter.defaultProps = {
  className: undefined,
  editing: true,
  formIsDirty: false,
  hasValidationError: false,
  onCancel: () => {},
  saveError: '',
  saving: false,
  buttonNamePrefix: 'formFooter',
  saveButtonDisabled: false,
  isDraft: false,
};

// Leave these until all components using this component are converted to TS
FormRowButtonFooter.propTypes = {
  className: PropTypes.string,
  editing: PropTypes.bool,
  formIsDirty: PropTypes.bool,
  hasValidationError: PropTypes.bool,
  onCancel: PropTypes.func,
  onDiscard: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saveError: PropTypes.string,
  saving: PropTypes.bool,
  buttonNamePrefix: PropTypes.string,
  saveButtonDisabled: PropTypes.bool,
  isDraft: PropTypes.bool,
};

export default FormRowButtonFooter;
