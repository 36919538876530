import React from 'react';
import { connect } from 'react-redux';
import { Body, Link } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import Footer from 'baas-ui/app/footer';
import { TopNav } from 'baas-ui/nav';
import { getSettingsState } from 'baas-ui/selectors';
import { useDarkMode } from 'baas-ui/theme';
import { RootState } from 'baas-ui/types';
import sadfacegif from 'baas-static/images/sadface.gif';

interface ReduxStateProps {
  showCloudNav?: boolean;
}

interface OwnProps {
  showTopNav?: boolean;
  showFooter?: boolean;
}

export type Props = ReduxStateProps & OwnProps;

export enum TestSelector {
  NotFoundPage = 'not-found-page',
}

const NotFound = ({ showTopNav, showFooter, showCloudNav }: Props) => {
  const darkMode = useDarkMode();

  return (
    <div>
      {!showCloudNav && showTopNav && <TopNav />}
      <div
        data-testid={TestSelector.NotFoundPage}
        data-cy={'not-found-page'}
        className={classNames('not-found', 'not-found-is-spaced', {
          'not-found-light-mode': !darkMode,
          'not-found-dark-mode': darkMode,
        })}
      >
        <div className="not-found-error-message-container">
          <div className="not-found-container">
            <img className="not-found-image" src={sadfacegif} alt="a sad computer" />
            <div className="not-found-code">
              &#123; status: 404, <br />
              &nbsp;&nbsp;message: &#34;Document Not Found&#34; &#125;
            </div>
          </div>
          <Body className="not-found-text">Oops! We can&#39;t find the page you were looking for.</Body>

          <Body className="not-found-text">
            If you&#39;re experiencing a critical issue, please reach out to Chat Support through our{' '}
            <Link className="not-found-text-link" href="https://www.mongodb.com/company/contact" target="_blank">
              Contact Us
            </Link>{' '}
            page.
          </Body>
        </div>
      </div>
      {showFooter && <Footer />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { showCloudNav } = getSettingsState(state);

  return { showCloudNav };
};

export default connect(mapStateToProps)(NotFound);
