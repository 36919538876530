import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  HostName,
  SecondarySideNavConfigInterface,
  SideNavOptionInterface,
  useCloudNavContext,
} from '@lg-private/cloud-nav';

import { setShowSideNav } from 'baas-ui/home/actions';
import { getAppState, getServiceState, getSettingsState } from 'baas-ui/selectors';
import { isSelfHostedOrAtlasMongoService } from 'baas-ui/services/registry';
import { RootState } from 'baas-ui/types';
import rootUrl from 'baas-ui/urls';
import {
  isEndpointsAndDataApiAllowed,
  isGraphQLFeatureAllowed,
  isHostingFeaturedAllowed,
  isSyncFeatureAllowed,
  isThirdPartyServicesAllowed,
} from 'baas-ui/util';

export const filterDeprecatedItems = (
  items: SideNavOptionInterface[],
  allowedDeprecatedFeatures: { [key: string]: boolean | undefined }
): SideNavOptionInterface[] => items.filter((item) => allowedDeprecatedFeatures[item.label] ?? true);

// useSideNav causes the secondary side nav (SSN) of CloudNavLayout to render. This will
// mainly be used within app specific (/groups/*/apps/*/**) pages
export const useSideNav = () => {
  const { setSecondaryNav } = useCloudNavContext();
  const dispatch = useDispatch();

  const app = useSelector((state: RootState) => getAppState(state).app);
  const svcsById = useSelector((state: RootState) => getServiceState(state).svcsById);

  const groupId = app?.groupId ?? '';
  const appId = app?.id ?? '';

  const { showCloudNav } = useSelector((state: RootState) => getSettingsState(state));
  const appsUrl = rootUrl.groups().group(groupId).apps();
  const appUrl = appsUrl.app(appId);

  const endpointsAndDataAPIAllowed = isEndpointsAndDataApiAllowed();
  const syncAllowed = isSyncFeatureAllowed();
  const graphqlAllowed = isGraphQLFeatureAllowed();
  const hostingAllowed = isHostingFeaturedAllowed();
  const thirdPartyServicesAllowed = isThirdPartyServicesAllowed();

  const secondaryNav: SecondarySideNavConfigInterface | undefined = useMemo(() => {
    const buildSectionItems = [
      {
        id: 'deviceSDK',
        label: 'Atlas Device SDK',
        link: {
          host: HostName.BAAS,
          path: appUrl.sdks().list(),
          matcher: (url: string) => url.includes(appUrl.sdks().list()),
        },
      },
      {
        id: 'sync',
        label: 'Device Sync',
        link: {
          host: HostName.BAAS,
          path: appUrl.sync().root(),
          matcher: (url: string) => url.includes(appUrl.sync().root()),
        },
      },
      {
        id: 'graphql',
        label: 'GraphQL',
        link: {
          host: HostName.BAAS,
          path: appUrl.graphql().root(),
          matcher: (url: string) => url.includes(appUrl.graphql().root()),
        },
      },
      {
        id: 'functions',
        label: 'Functions',
        link: {
          host: HostName.BAAS,
          path: appUrl.functions().list(),
          matcher: (url: string) =>
            url.includes(appUrl.functions().list()) || url.includes(appUrl.dependencies().list()),
        },
      },
      {
        id: 'triggers',
        label: 'Triggers',
        link: {
          host: HostName.BAAS,
          path: appUrl.triggers().list(),
          matcher: (url: string) => url.includes(appUrl.triggers().list()),
        },
      },
      {
        id: 'endpoints',
        label: 'HTTPS Endpoints',
        link: {
          host: HostName.BAAS,
          path: appUrl.endpoints().list(),
          matcher: (url: string) => url.includes(appUrl.endpoints().list()),
        },
      },
      {
        id: 'thirdPartyServices',
        label: '3rd Party Services',
        link: {
          host: HostName.BAAS,
          path: appUrl.services().list(),
          matcher: (url: string) =>
            url.includes(appUrl.services().list()) &&
            !Object.values(svcsById).some((svc) => isSelfHostedOrAtlasMongoService(svc) && url.includes(svc.id)),
        },
      },
      {
        id: 'values',
        label: 'Values',
        link: {
          host: HostName.BAAS,
          path: appUrl.values().list(),
          matcher: (url: string) =>
            url.includes(appUrl.values().list()) ||
            url.includes(appUrl.secrets().list()) ||
            url.includes(appUrl.environmentValues().list()),
        },
      },
    ];

    const manageSectionItems = [
      {
        id: 'dataSources',
        label: 'Linked Data Sources',
        link: {
          host: HostName.BAAS,
          path: appUrl.clusters().list(),
          matcher: (url: string) =>
            url.includes(appUrl.clusters().list()) ||
            Object.values(svcsById).some((svc) => isSelfHostedOrAtlasMongoService(svc) && url.includes(svc.id)),
        },
      },
      {
        id: 'deployment',
        label: 'Deployment',
        link: {
          host: HostName.BAAS,
          path: appUrl.deploy().list(),
          matcher: (url: string) => url.includes(appUrl.deploy().list()),
        },
      },
      {
        id: 'hosting',
        label: 'Hosting',
        link: {
          host: HostName.BAAS,
          path: appUrl.hosting().list(),
          matcher: (url: string) => url.includes(appUrl.hosting().list()),
        },
      },
      {
        id: 'logs',
        label: 'Logs',
        link: {
          host: HostName.BAAS,
          path: appUrl.logs().list(),
          matcher: (url: string) => url.includes(appUrl.logs().list()),
        },
      },
      {
        id: 'settings',
        label: 'App Settings',
        link: {
          host: HostName.BAAS,
          path: appUrl.settings().list(),
          matcher: (url: string) => url.includes(appUrl.settings().list()),
        },
      },
      {
        id: 'pushNotifications',
        label: 'Push Notifications',
        link: {
          host: HostName.BAAS,
          path: Object.values(svcsById).some((svc) => svc.type === 'gcm')
            ? appUrl.push().list()
            : appUrl.push().config(),
          matcher: (url: string) => url.includes(appUrl.push().list()),
        },
      },
    ];

    const allowedDeprecatedFeatures = {
      'HTTPS Endpoints': endpointsAndDataAPIAllowed,
      'Device Sync': syncAllowed,
      'Atlas Device SDK': syncAllowed,
      GraphQL: graphqlAllowed,
      Hosting: hostingAllowed,
      '3rd Party Services': thirdPartyServicesAllowed,
    };

    return groupId && appId
      ? {
          title: 'App Services',
          parentId: 'triggers',
          items: [
            {
              id: 'allApps',
              label: 'All Apps',
              link: {
                host: HostName.BAAS,
                path: appsUrl.list(),
                matcher: (url: string) => url.endsWith('/apps'),
              },
            },
            {
              id: 'dashboard',
              label: 'Dashboard',
              link: {
                host: HostName.BAAS,
                path: appUrl.dashboard(),
                matcher: (url: string) => url.includes(appUrl.dashboard()),
              },
            },
            {
              id: 'dataAccess',
              label: 'Data Access',
              children: [
                {
                  id: 'rules',
                  label: 'Rules',
                  link: {
                    host: HostName.BAAS,
                    path: appUrl.rules().list(),
                    matcher: (url: string) => url.includes(appUrl.rules().list()),
                  },
                },
                {
                  id: 'schema',
                  label: 'Schema',
                  link: {
                    host: HostName.BAAS,
                    path: appUrl.schemas().root(),
                    matcher: (url: string) => url.includes(appUrl.schemas().root()),
                  },
                },
                {
                  id: 'authUsers',
                  label: 'Auth & Users',
                  link: {
                    host: HostName.BAAS,
                    path: appUrl.auth().users().list(),
                    matcher: (url: string) => url.includes(appUrl.auth().list()),
                  },
                },
              ],
            },
            {
              id: 'build',
              label: 'Build',
              children: filterDeprecatedItems(buildSectionItems, allowedDeprecatedFeatures),
            },
            {
              id: 'manage',
              label: 'Manage',
              children: filterDeprecatedItems(manageSectionItems, allowedDeprecatedFeatures),
            },
          ],
        }
      : undefined;
  }, [
    groupId,
    appId,
    svcsById,
    endpointsAndDataAPIAllowed,
    syncAllowed,
    graphqlAllowed,
    hostingAllowed,
    thirdPartyServicesAllowed,
  ]);

  // if baas-ui is using the new CloudNavLayout component, render the secondary side nav with all baas-specific links
  // setSecondaryNav should only be called from a descendant of the CloudNavLayout component, so if showCloudNav is
  // false, then skip making the call to setSecondaryNav
  useEffect(() => {
    if (showCloudNav) {
      dispatch(setShowSideNav(true));
      setSecondaryNav(secondaryNav);
    }
  }, [secondaryNav, showCloudNav, setSecondaryNav]);
};
