import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCloudNavContext } from '@lg-private/cloud-nav';

import { getSettingsState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';

// useAppsPageNav
// * causes the primary side nav of the CloudNavLayout component to render
// * hides the secondary side nav
// * hides the Apps resource select
// This will be used on the All Apps page and in our create app pages
export const useAppsPageNav = () => {
  const { setIsPrimaryNavExpanded, setSecondaryNav, setProjectResourcePath } = useCloudNavContext();
  const { showCloudNav } = useSelector((state: RootState) => getSettingsState(state));

  useEffect(() => {
    if (showCloudNav) {
      setSecondaryNav(undefined);
      setProjectResourcePath([]);
      setIsPrimaryNavExpanded(false);
    }
  }, [showCloudNav]);
};
