import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Callout from '@leafygreen-ui/callout';
import { BasicEmptyState } from '@leafygreen-ui/empty-state';
import { palette } from '@leafygreen-ui/palette';
import { SegmentedControl, SegmentedControlOption } from '@leafygreen-ui/segmented-control';
import { BackLink, Body, H2, Link as LGLink } from '@leafygreen-ui/typography';
import classNames from 'classnames';
import { compose } from 'redux';

import { clearAll, redirectTo as redirectToAction } from 'baas-ui/actions';
import DocLink from 'baas-ui/common/components/doc-link';
import { LoadingWrapper } from 'baas-ui/common/components/loading-wrapper';
import { Title, TitleArea } from 'baas-ui/common/components/title';
import { useLocalStorage } from 'baas-ui/common/hooks/use-local-storage';
import usePoller from 'baas-ui/common/hooks/use-poller';
import useSessionStorage from 'baas-ui/common/hooks/use-session-storage';
import { docLinks } from 'baas-ui/common/links';
import { domainDeprecationBannerDismissed } from 'baas-ui/common/local-storage-keys';
import { selectedTemplateIdKey } from 'baas-ui/common/session-storage-keys';
import { GROUP_OWNER } from 'baas-ui/constants';
import * as actions from 'baas-ui/home/actions';
import {
  clearClusterProvisionToastState as clearClusterProvisionToastStateAction,
  clearDataSourceErrors as clearDataSourceErrorsAction,
} from 'baas-ui/home/actions';
import AppsEmptyStateSVG from 'baas-ui/home/apps/empty-state/AppsEmptyStateSVG';
import useTemplates from 'baas-ui/home/common/hooks/use-templates';
import { templatesNotAvailableErrorMessage } from 'baas-ui/home/constants';
import { TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { getAppData } from 'baas-ui/home/sagas';
import { AppDataResult, AppProduct, appServicesVisibleProductTypes, RequestError } from 'baas-ui/home/types';
import { MeasurementsByName, UsageByMeasurement } from 'baas-ui/measurements/types';
import { MetricUsagesByName } from 'baas-ui/metrics/types';
import { DEFAULT_METRICS } from 'baas-ui/metrics/utils';
import { TopNav } from 'baas-ui/nav';
import { useAppsPageNav } from 'baas-ui/nav/useAppsPageNav';
import { AsyncDispatch, AsyncDispatchPayload } from 'baas-ui/redux_util';
import { getHomeState, getSettingsState, getUserProfileState } from 'baas-ui/selectors';
import { useDarkMode } from 'baas-ui/theme';
import { Track, track, TrackOnRender } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import urls, { replaceBaseUrl } from 'baas-ui/urls';
import { MeasurementName, PartialApp, RoleAssignment } from 'admin-sdk';

import AppCard from './app-card';
import AppsUsage from './apps-usage';
import DeleteAppConfirmation from './DeleteAppConfirmation';

import './apps.less';
import 'baas-ui/common/styles/banner.less';

export enum TestSelector {
  AppContainer = 'app-container',
  CreateAppButton = 'create-app',
  TemplateStarterAppsMenu = 'template-starter-apps-menu',
  ErrorBannerApps = 'error-banner-apps',
  ErrorBannerMetrics = 'error-banner-metrics',
  DomainDeprecationBanner = 'domain-deprecation-banner',
  CloudServicesLink = 'cloud-services-link',
  DomainDeprecationDocsLink = 'domain-deprecation-docs-link',
  DataServicesOverviewLink = 'data-services-overview-link',
  AppServicesCallout = 'app-services-callout',
  AppServicesLink = 'app-services-link',
  EmptyState = 'no-apps-empty-state',
  EmptyStateCreateAppButton = 'empty-state-create-app-button',
  EmptyStateTriggerTemplateButton = 'empty-state-create-trigger-template-app',
  UserPermissionBanner = 'user-permission-banner',

  SortOptionAlphabetical = 'sort-option-alphabetical',
  SortOptionLastModified = 'sort-option-lastModified',
}

interface StateProps {
  apps: PartialApp[];
  groupId: string;
  recaptchaSiteKey: string;
  userIsProjectOwner: boolean;
  cloudUIBaseUrl: string;
  showCloudNav?: boolean;
}

interface DispatchProps {
  deleteApp(appId: string): AsyncDispatchPayload<void>;
  loadApps(groupId: string, products: AppProduct[]): AsyncDispatchPayload<PartialApp[]>;
  setGroupId(groupId: string): void;
  getData(app: PartialApp, isPricingChangeEnabled: boolean): Promise<AppDataResult>;
  redirectTo(url: string, options?: { replace: boolean }): void;
  clearAppState(): void;
  clearDataSourceErrors(): void;
  clearClusterProvisionToastState(): void;
}

// this should be removed in favor of `useParams` hook later when we update react-dom
type RouteProps = RouteComponentProps<{
  groupId: string;
}>;

interface OwnProps {
  clusterStatePoller: ReturnType<typeof usePoller>;
}

export type Props = StateProps & DispatchProps & RouteProps & OwnProps;

const sectionHeaderClassname = 'section-header';
const appCardClassname = 'app-card';

export const updateAppMeasurements = (
  app: PartialApp,
  measurementsByName: MeasurementsByName,
  usageByMeasurement: UsageByMeasurement
): UsageByMeasurement => {
  return {
    [MeasurementName.ComputeTime]: {
      appMeasurements: [
        ...usageByMeasurement[MeasurementName.ComputeTime].appMeasurements,
        {
          app,
          measurement: { ...measurementsByName.compute_time },
        },
      ],
      totalUsage: usageByMeasurement[MeasurementName.ComputeTime].totalUsage + measurementsByName.compute_time.usage,
    },
    [MeasurementName.DataOut]: {
      appMeasurements: [
        ...usageByMeasurement[MeasurementName.DataOut].appMeasurements,
        {
          app,
          measurement: { ...measurementsByName.data_out },
        },
      ],
      totalUsage: usageByMeasurement[MeasurementName.DataOut].totalUsage + measurementsByName.data_out.usage,
    },
    [MeasurementName.RequestCount]: {
      appMeasurements: [
        ...usageByMeasurement[MeasurementName.RequestCount].appMeasurements,
        {
          app,
          measurement: { ...measurementsByName.request_count },
        },
      ],
      totalUsage: usageByMeasurement[MeasurementName.RequestCount].totalUsage + measurementsByName.request_count.usage,
    },
    [MeasurementName.SyncTime]: {
      appMeasurements: [
        ...usageByMeasurement[MeasurementName.SyncTime].appMeasurements,
        {
          app,
          measurement: { ...measurementsByName.sync_time },
        },
      ],
      totalUsage: usageByMeasurement[MeasurementName.SyncTime].totalUsage + measurementsByName.sync_time.usage,
    },
  };
};

enum SortCriteria {
  LastModified = 'lastModified',
  Alphabetical = 'alphabetical',
}

export const sortByLastModified = (partialApps: PartialApp[]): PartialApp[] => {
  return partialApps.sort((first, second) => second.lastModified - first.lastModified);
};

export const sortByAlphabeticalOrder = (partialApps: PartialApp[]): PartialApp[] => {
  return partialApps.sort((first, second) => first.name.localeCompare(second.name, undefined, { caseFirst: 'upper' }));
};

export const sortBySortCriteria = (partialApps: PartialApp[], sortCriteria: SortCriteria): PartialApp[] => {
  switch (sortCriteria) {
    case SortCriteria.LastModified:
      return sortByLastModified(partialApps);
    default:
      return sortByAlphabeticalOrder(partialApps);
  }
};

const StyledBackToDataServicesLink = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBanner = styled(Banner)(({ theme }) => ({
  width: theme.banner.width,
}));

const StyledDomainDeprecationHeader = styled(Body)`
  color: inherit;
  font-weight: bold;
`;

const StyledSpan = styled.span`
  color: ${palette.blue.base};
  font-weight: 500;
`;

const StyledBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

const Apps = ({
  apps,
  clearDataSourceErrors,
  clearClusterProvisionToastState,
  deleteApp,
  getData,
  groupId,
  loadApps,
  match,
  redirectTo,
  clearAppState,
  setGroupId,
  userIsProjectOwner,
  cloudUIBaseUrl,
  showCloudNav = false,
}: Props) => {
  useAppsPageNav();

  const [loadAppsError, setLoadAppsError] = useState(RequestError.NoError);
  const [loadingApps, setLoadingApps] = useState(true);
  const [, setSelectedTemplateId] = useSessionStorage(selectedTemplateIdKey(groupId), '');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [appToDelete, setAppToDelete] = useState({ name: '', id: '' });
  const [sortCriteria, setSortCriteria] = useState(SortCriteria.Alphabetical);

  const [usageByMeasurement, setUsageByMeasurement] = React.useState<Readonly<UsageByMeasurement>>({
    [MeasurementName.ComputeTime]: { appMeasurements: [], totalUsage: 0 },
    [MeasurementName.DataOut]: { appMeasurements: [], totalUsage: 0 },
    [MeasurementName.RequestCount]: { appMeasurements: [], totalUsage: 0 },
    [MeasurementName.SyncTime]: { appMeasurements: [], totalUsage: 0 },
  });

  const [groupTotalMetrics, setGroupTotalMetrics] = React.useState<MetricUsagesByName>({ ...DEFAULT_METRICS });
  const [appTotalMetrics, setAppTotalMetrics] = React.useState<Record<string, MetricUsagesByName>>({});

  const [isDomainDeprecationBannerDismissed, setIsDomainDeprecationBannerDismissed] = useLocalStorage(
    domainDeprecationBannerDismissed(match.params.groupId),
    false
  );

  const darkMode = useDarkMode();
  const [templates, templatesLoading, templatesError, loadTemplates] = useTemplates();
  const [templatesDisabled, setTemplatesDisabled] = useState(false);

  React.useEffect(() => {
    clearDataSourceErrors();
    clearClusterProvisionToastState();
    clearAppState();
  }, []);

  React.useEffect(() => {
    const loadDataAsync = async () => {
      // TODO use `useParam` hook after upgrading @types/react-router-dom
      if (match.params.groupId) {
        const matchedGroupId = match.params.groupId;
        setLoadingApps(true);
        setLoadAppsError(RequestError.NoError);
        try {
          if (matchedGroupId !== groupId) {
            setGroupId(matchedGroupId);
          }
          await loadApps(matchedGroupId, appServicesVisibleProductTypes);
          await loadTemplates();
        } catch (e) {
          setLoadAppsError(e);
        } finally {
          setLoadingApps(false);
        }
      }
    };
    loadDataAsync();
  }, [match.params.groupId]);

  React.useEffect(() => {
    setTemplatesDisabled(!!templatesError || templates.length === 0);
  }, [templatesError, templates]);

  const createAppUrl = urls.groups().group(groupId).apps().create();

  const handleCreateTemplate = () => {
    track('APPLICATION.TEMPLATE_STARTER_APP_CLICKED', {
      templateId: TemplateIdentifier.TriggersDatabase,
    });
    setSelectedTemplateId(TemplateIdentifier.TriggersDatabase);
    redirectTo(createAppUrl);
  };

  const handleDeleteApp = (appId: string) => {
    setLoadAppsError(RequestError.NoError);
    deleteApp(appId)
      .then(() => {
        // reset the app and group metrics
        setGroupTotalMetrics({ ...DEFAULT_METRICS });
        setAppTotalMetrics({});
        setUsageByMeasurement({
          [MeasurementName.ComputeTime]: { appMeasurements: [], totalUsage: 0 },
          [MeasurementName.DataOut]: { appMeasurements: [], totalUsage: 0 },
          [MeasurementName.RequestCount]: { appMeasurements: [], totalUsage: 0 },
          [MeasurementName.SyncTime]: { appMeasurements: [], totalUsage: 0 },
        });
        return loadApps(groupId, appServicesVisibleProductTypes);
      })
      .catch((e) => {
        setLoadAppsError(e);
      })
      .finally(() => setIsDeleteConfirmationOpen(false));
  };

  const hasApps = apps.length > 0;

  const atlasOverviewUrl = `${cloudUIBaseUrl}/v2/${groupId}#/overview`;
  const isLoading = loadingApps || templatesLoading;

  return (
    <div
      data-cy="apps"
      className={classNames({
        'apps-wrapper': !showCloudNav,
        'apps-page-content': showCloudNav,
      })}
    >
      {hasApps && <TrackOnRender event="APPLICATION.LIST_VIEWED" />}
      <TitleArea />
      {!showCloudNav && <TopNav />}
      <LoadingWrapper
        className={classNames({
          'apps-loading-wrapper': !showCloudNav,
        })}
        isLoading={isLoading || !groupId}
      >
        {!isLoading && groupId && (
          <div className="apps-container" data-test-selector={TestSelector.AppContainer}>
            <div
              className={classNames('apps', {
                'apps-with-mongo-nav': !showCloudNav,
              })}
            >
              <Title>Apps</Title>
              <div className={sectionHeaderClassname}>
                <BackLink href={atlasOverviewUrl} data-testid={TestSelector.DataServicesOverviewLink}>
                  <StyledBackToDataServicesLink>
                    <StyledSpan>Back to Data Services</StyledSpan>
                  </StyledBackToDataServicesLink>
                </BackLink>
                <div className={`${sectionHeaderClassname} ${sectionHeaderClassname}-title`}>
                  <H2>Applications</H2>
                  <div className={`${sectionHeaderClassname}-title-controls`}>
                    <Button
                      data-cy="create-triggers-template-app"
                      variant="primaryOutline"
                      disabled={templatesLoading || templatesDisabled}
                      onClick={handleCreateTemplate}
                    >
                      Use a Triggers Template
                    </Button>
                    <Track event="APPLICATION.CREATE_NEW_CLICKED" properties={{ context: 'APPS_LIST' }}>
                      <Link to={createAppUrl}>
                        <Button
                          data-cy="create-app-button"
                          variant="primary"
                          disabled={loadingApps}
                          data-test-selector={TestSelector.CreateAppButton}
                        >
                          Create a New App
                        </Button>
                      </Link>
                    </Track>
                  </div>
                </div>
              </div>
              <StyledBannerContainer>
                <Callout variant="note" title="APP SERVICES HAS MOVED" data-testid={TestSelector.AppServicesCallout}>
                  This App Services landing page has moved to be accessible via the “View All Apps” button in each
                  individual service tab (Triggers, Data API, Device & Edge Sync) within the Data Services UI.{' '}
                  <LGLink
                    href="https://www.mongodb.com/docs/atlas/app-services/introduction/"
                    data-testid={TestSelector.AppServicesLink}
                    hideExternalIcon
                  >
                    Learn more about App Services.
                  </LGLink>
                </Callout>
                {!userIsProjectOwner && (
                  <StyledBanner data-testid={TestSelector.UserPermissionBanner} variant="danger">
                    You do not have the required permissions to create/edit applications for this project.
                    Creating/Editing an App Service requires the{' '}
                    <DocLink href={docLinks.Atlas.UserRoles}>Project Owner</DocLink> role.
                  </StyledBanner>
                )}
                {loadAppsError !== RequestError.NoError && (
                  <StyledBanner variant="danger" data-testid={TestSelector.ErrorBannerApps}>
                    The request to fetch all apps has failed. Please try again later.
                  </StyledBanner>
                )}
                {templatesDisabled && <StyledBanner variant="danger">{templatesNotAvailableErrorMessage}</StyledBanner>}
                {!isDomainDeprecationBannerDismissed && (
                  <StyledBanner
                    dismissible
                    variant={Variant.Warning}
                    onClose={() => setIsDomainDeprecationBannerDismissed(true)}
                    data-testid={TestSelector.DomainDeprecationBanner}
                  >
                    <StyledDomainDeprecationHeader>Realm Domain Deprecation</StyledDomainDeprecationHeader>
                    realm.mongodb.com is deprecated in favor of{' '}
                    <LGLink
                      data-testid={TestSelector.CloudServicesLink}
                      href={replaceBaseUrl(window.settings.adminUrl, window.location.href)}
                      hideExternalIcon
                    >
                      services.cloud.mongodb.com
                    </LGLink>
                    . While realm.mongodb.com still continues to work, we recommend updating your bookmarks and issuing
                    API requests to services.cloud.mongodb.com for a seamless transition in the future.{' '}
                    <LGLink
                      data-testid={TestSelector.DomainDeprecationDocsLink}
                      href={docLinks.General.DomainMigration.LearnMore}
                    >
                      Learn More
                    </LGLink>
                  </StyledBanner>
                )}
              </StyledBannerContainer>
              {hasApps ? (
                <>
                  <AppsUsage
                    usageByMeasurement={usageByMeasurement}
                    apps={apps}
                    groupTotalMetrics={groupTotalMetrics}
                    appTotalMetrics={appTotalMetrics}
                  />
                  <SegmentedControl
                    name={'sortCriteria'}
                    aria-controls="app-sort"
                    label={'Sort By'}
                    darkMode={darkMode}
                    size={'default'}
                    onChange={(value: SortCriteria) => setSortCriteria(value)}
                  >
                    <SegmentedControlOption
                      value={SortCriteria.Alphabetical}
                      data-testid={TestSelector.SortOptionAlphabetical}
                    >
                      A-Z
                    </SegmentedControlOption>
                    <SegmentedControlOption
                      value={SortCriteria.LastModified}
                      data-testid={TestSelector.SortOptionLastModified}
                    >
                      Last Updated
                    </SegmentedControlOption>
                  </SegmentedControl>
                  <div className={`${appCardClassname}-loading-container`}>
                    <div data-cy="app-card-container" className={`${appCardClassname}-container`}>
                      {sortBySortCriteria(apps, sortCriteria)
                        .filter((app) => app.product !== 'charts')
                        .map((app: PartialApp) => (
                          <AppCard
                            app={app}
                            appMetrics={appTotalMetrics[app.id] ?? { ...DEFAULT_METRICS }}
                            key={app.id}
                            onDeleteApp={() => {
                              setAppToDelete(app);
                              setIsDeleteConfirmationOpen(true);
                            }}
                            getData={getData}
                            onClearAppState={clearAppState}
                            appUrl={urls.groups().group(app.groupId).apps().app(app.id).get()}
                            environmentUrl={urls.groups().group(app.groupId).apps().app(app.id).deploy().environment()}
                            onClick={() => {
                              track('APPLICATION.APPLICATION_CLICKED', {
                                app_id: app.id,
                                app_name: app.name,
                                client_app_id: app.clientAppId,
                              });
                            }}
                            onGetDataComplete={(data) =>
                              setUsageByMeasurement((prev) => updateAppMeasurements(app, data, prev))
                            }
                          />
                        ))}
                    </div>
                  </div>
                  <DeleteAppConfirmation
                    appName={appToDelete.name}
                    isOpen={isDeleteConfirmationOpen}
                    onConfirmDelete={() => handleDeleteApp(appToDelete.id)}
                    onCancel={() => setIsDeleteConfirmationOpen(false)}
                  />
                </>
              ) : (
                <BasicEmptyState
                  title="You have no apps yet"
                  description="Create an application from scratch or from a template"
                  graphic={<AppsEmptyStateSVG />}
                  externalLink={<DocLink href={docLinks.General.AppServices}>Learn more about App Services</DocLink>}
                  primaryButton={
                    <Button
                      data-testid={TestSelector.EmptyStateCreateAppButton}
                      data-cy={TestSelector.EmptyStateCreateAppButton}
                      variant="primary"
                      disabled={loadingApps}
                      onClick={() => {
                        track('APPLICATION.CREATE_NEW_CLICKED', { context: 'APPS_LIST' });
                        redirectTo(createAppUrl);
                      }}
                    >
                      Create a New App
                    </Button>
                  }
                  secondaryButton={
                    <Button
                      data-testid={TestSelector.EmptyStateTriggerTemplateButton}
                      data-cy={TestSelector.EmptyStateTriggerTemplateButton}
                      variant="primaryOutline"
                      disabled={templatesLoading || templatesDisabled}
                      onClick={handleCreateTemplate}
                    >
                      Use a Triggers Template
                    </Button>
                  }
                />
              )}
            </div>
          </div>
        )}
      </LoadingWrapper>
    </div>
  );
};

export const mapStateToProps = (state: RootState) => {
  const { apps, groupId } = getHomeState(state);
  const userProfile = getUserProfileState(state);
  const { recaptchaSiteKey } = getSettingsState(state);
  const { cloudUIBaseUrl, showCloudNav } = getSettingsState(state);
  const userIsProjectOwner = !!userProfile?.roles?.some(
    (role: RoleAssignment) => role.groupId === groupId && role.roleName === GROUP_OWNER
  );

  return {
    apps,
    groupId,
    recaptchaSiteKey,
    userIsProjectOwner,
    cloudUIBaseUrl,
    showCloudNav,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  loadApps: (groupId: string, products: AppProduct[]) => dispatch(actions.loadApps({ groupId, products })),
  deleteApp: (groupId: string) => (appId: string) => dispatch(actions.deleteApp({ groupId, appId })),
  setGroupId: (groupId: string) => dispatch(actions.setGroupId(groupId)),
  getData: getAppData(dispatch),
  redirectTo: (url: string, options?: { replace: boolean }) => dispatch(redirectToAction(url, options)),
  clearAppState: () => dispatch(clearAll()),
  clearDataSourceErrors: () => dispatch(clearDataSourceErrorsAction()),
  clearClusterProvisionToastState: () => dispatch(clearClusterProvisionToastStateAction()),
});

const mergeProps = (
  { groupId, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
  { deleteApp, ...otherDispatchProps }: ReturnType<typeof mapDispatchToProps>,
  ownProps: RouteProps
) => ({
  ...otherStateProps,
  ...otherDispatchProps,
  ...ownProps,
  deleteApp: deleteApp(groupId),
  groupId,
});

export { Apps as AppsComponent };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps, mergeProps))(Apps);
